@use '@typography' as *;
@use '@queries' as *;
@use '@fixed-size' as *;
@use '@content' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0;

  @include min-428-break {
    gap: 8px;
    padding: 10px 0;
  }

  .icon {
    @include singleFixedSize(20px);
    color: var(--global-purple600);

    @include min-428-break {
      @include singleFixedSize(28px);
    }
  }

  .title {
    @include typography-s;
    @include weight-medium;
    color: var(--cst-dropitem-text-default);

    @include min-428-break {
      @include typography-m;
    }

    &-opened {
      color: var(--cst-accordion-title-default);
    }
  }
}

.content {
  .subtitle {
    @include typography-s;
    @include weight-medium;
    color: var(--cst-cards-category-subtitle-default);

    @include min-428-break {
      @include typography-base;
    }
  }
}

.img-container {
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  @include containerPaddings;
  @include fixedSize(100%, 136px);
  border-radius: var(--cornerradius16);

  @include min-428-break {
    @include fixedSize(100%, 206px);
  }

  @include min-744-break {
    @include fixedSize(400px, 206px);
  }

  .img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}
